import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";


const breadCrumbLevels = {
    Accueil: "/",
    "Consulting Analytics": "/fr/consultation-analytique",
    "Gestion des Tags": "/fr/gestion-des-tags"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/tag-management"
);

// Schéma
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Qu'est-ce qu'un système de gestion des tags?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Un système de gestion des tags est une bibliothèque JavaScript pour implémenter et configurer des tags d'analyse et de marketing. Les tags HTML, les extraits de code JavaScript ou les pixels de suivi ajoutent des fonctionnalités à votre site web ou application et peuvent être installés en quelques clics. Une fois qu'un tag est publié dans le conteneur, le code sera généré et ajouté à la page du site web (DOM). Aucun nouveau code source n'est déployé sur le serveur."
        }
      },
      {
      "@type": "Question",
      "name": "Quel problème un TMS résout-il?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Au fil du temps, de plus en plus de plateformes de médias sociaux ont créé leurs propres tags (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, etc.) et les sites web sont devenus encombrés de leurs extraits de code. En conséquence, les entreprises ont vu leurs coûts de développement web augmenter. Les gestionnaires de tags résolvent ces problèmes car les implémentations peuvent être mises à l'échelle et ainsi gagner du temps, ce qui signifie que les entreprises peuvent économiser de l'argent."
      }
    }
  ]
}
`;

const TagManagement = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Gestion des Tags expliquée (avec comparaison ${currentYear})`}
          description="Découvrez ce que font les systèmes de gestion des tags, leurs avantages et pourquoi ils sont devenus si populaires (y compris une comparaison des fournisseurs de TMS actuels)."
          lang="fr"
          canonical="/fr/gestion-des-tags"
          image="tag-management-hero"
          alternateLangs={alternateLangs}
        />
        <MainContent article>
          <ImgScreenshot
            src="tag-management/tag-management-hero.png"
            alt="logos des fournisseurs de plateformes TMS côte à côte sur un fond sombre"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Gestion des Tags</H>
          <p className="baseline">
La gestion des tags gagne en popularité et attire une communauté croissante. La plupart des sites web modernes ne peuvent plus se passer d'un système de gestion des tags, si bien qu'aujourd'hui environ <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% de tous les sites web utilisent un gestionnaire de tags</a>.
</p>
<p>Mais il s'agit d'une tendance technologique de marketing assez récente.</p>
<p>
  Au cours des dernières années, un nouveau secteur de solutions de gestion des tags s'est transformé. Même aujourd'hui, de nouvelles solutions entrent sur le marché en raison de l'évolution des réglementations et des technologies marketing.
</p>
<p className="baseline">
  Une bonne illustration de l'intérêt croissant est la recherche mondiale de "Google Tag Manager" (GTM) - le système de gestion des tags (TMS) le plus populaire avec une <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">part de marché de 94% sur l'ensemble d'internet</a> :
</p>
<ImgScreenshot
  src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
  alt="Graphique de Google Trends montrant l'intérêt croissant pour Google Tag Manager"
  caption="L'augmentation des recherches pour 'Google Tag Manager' depuis 2010 illustre l'intérêt croissant pour les systèmes de gestion des tags. Ironiquement, le terme général 'gestion des tags' n'a jamais été aussi populaire que les fournisseurs de plateformes TMS."
  className="article-img"
/>
<p className="baseline">
  Bien que cette croissance extrême se poursuive depuis un certain temps, l'utilisation des gestionnaires de tags devrait <b>continuer à croître</b> avec un taux de croissance annuel de <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5,7% jusqu'en 2024.</a>
</p>
<H as="h2">Qu'est-ce qu'un système de gestion des tags ?</H>
<p>
  Un système de gestion des tags est une bibliothèque JavaScript pour implémenter et configurer des tags d'analyse et de marketing.
</p>
<p>
  Les tags HTML, les extraits de code JavaScript ou les pixels de suivi ajoutent des fonctionnalités à votre site web ou application et peuvent être installés en quelques clics.
</p>
<H as="h3">Exemples de tags</H>
<ul>
  <li>Suivi Google Analytics</li>
  <li>Suivi vidéo</li>
  <li>Pixels de reciblage (Facebook, Google Ads, Linkedin, etc.)</li>
  <li>Formulaires d'inscription</li>
</ul>
<p>
  Les implémentations de tags peuvent être effectuées via une interface utilisateur graphique (GUI) pour une installation facile.
</p>
<p>
  Une fois qu'un tag est publié dans le conteneur, le code sera généré et ajouté à la page du site web (DOM). Aucun nouveau code source n'est déployé sur le serveur.
</p>
<p>
  Le TMS le plus populaire est <Link to="/fr/qu-est-ce-que-google-tag-manager">Google Tag Manager</Link>. Il propose une variété de types de tags et de modèles disponibles pour les fournisseurs tiers, de sorte que vous pouvez implémenter des tags en quelques clics.
</p>
<p>L'implémentation repose sur 3 composants :</p>
<ul>
  <li><strong>Tags</strong> (code à ajouter à la page)</li>
  <li><strong>Déclencheurs</strong> (règles avec des conditions indiquant quand le tag doit être déclenché)</li>
  <li><strong>Variables</strong> (données dynamiques à utiliser dans les tags ou les déclencheurs)</li>
</ul>
<ImgScreenshot
  src="generic/google-tag-manager-overview-tab-en.png"
  alt="Vue d'ensemble de Google Tag Manager"
  className="article-img"
  caption="Vue d'ensemble de Google Tag Manager avec le panneau à gauche montrant les onglets pour la configuration des tags, des déclencheurs et des variables."
/>
<p>
  La configuration est effectuée à l'aide de formulaires simples et de champs de saisie au lieu de devoir coder. 
</p>
<p>
  Cela réduit les erreurs et rend l'implémentation des tags de site web <b>accessible aux spécialistes du marketing digital sans connaissances en programmation.</b>
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-configuration.png"
  alt="Configuration des déclencheurs dans GTM"
  className="article-img"
  caption="Configuration des déclencheurs dans GTM. Il définit quand un tag est exécuté. Celui-ci s'exécute lorsque tout élément HTML correspondant au sélecteur CSS est cliqué."
/>

<H as="h2">Quel problème un TMS résout-il ?</H>
<p>
  Avec le web 2.0 et l'amélioration des technologies marketing, de nouvelles opportunités pour les spécialistes du marketing digital ont émergé. Elles ont permis d'analyser le comportement des utilisateurs pour la segmentation et d'utiliser les données pour des campagnes publicitaires.
</p>
<p>Pour exécuter de telles campagnes ciblées, plusieurs <b>tags ou pixels</b> devaient être placés sur le site web. Ensuite, le pixel de suivi peut catégoriser les visiteurs et leur comportement pour les recibler sur Facebook ou Google Ads.</p>
<p>Au fil du temps, de plus en plus de plateformes de médias sociaux ont créé leurs propres tags (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, etc.) et les sites web sont devenus encombrés de leurs extraits de code.</p>
<p>Il n'était pas rare de voir 20 à 40 tags individuels dans la section <code>{`<head>`}</code> d'une page. </p>
<p>La base de ce système de balisage est une <Link to="/fr/suivi-des-événements">configuration de suivi des événements</Link> bien configurée. Tous les boutons pertinents doivent être équipés de fonctions JavaScript. Une fois qu'un élément est cliqué, le JavaScript notifiera le tag marketing pertinent.</p>
<p>Les tags nécessitaient principalement les mêmes métriques utilisateur, mais si vous vouliez ajouter une autre dimension ou métrique, cela devenait rapidement une tâche manuelle fastidieuse. Ainsi, la gestion de ces tags seuls devenait un problème encore plus grand.</p>
<p>Les développeurs devaient donc passer de nombreuses heures à coller du code JavaScript dans des éléments HTML. Savoir exactement comment le code devait être écrit ou quelles variables pouvaient être envoyées devenait une nouvelle tâche organisationnelle.</p>
<p>Les entreprises qui voulaient lancer des campagnes fréquemment devaient configurer le suivi des événements sur chaque nouvelle page de destination pour maintenir le suivi des campagnes. Maintenir les tags principaux, les paramètres et les variables à jour devenait une tâche supplémentaire.</p>
<p>Toute idée expérimentale de suivi d'une nouvelle interaction devenait un effort difficile à justifier. Les modifications de code devenaient trop laborieuses.</p>
<p>En bref, les changements et les expérimentations étaient difficiles et inflexibles. Mais le suivi des conversions devait être maintenu pour mesurer les performances des campagnes. Ne pas les mesurer correctement rendrait autrement difficile leur amélioration.</p>
<p>En conséquence, les entreprises ont vu leurs coûts de développement web augmenter. Le problème ne pouvait être résolu qu'avec des bibliothèques auto-écrites, ce qui a malheureusement conduit les équipes de développeurs à résoudre le problème en silos, réinventant la roue.</p>
<p>Le marché était prêt pour l'innovation.</p>

<p>Les gestionnaires de tags résolvent ces problèmes car les implémentations peuvent être mises à l'échelle et ainsi <b>gagner du temps</b>, ce qui signifie que les entreprises peuvent <b>économiser de l'argent</b>.</p>
<p>Ironiquement, un système de gestion des tags est également implémenté via une <Link to="/fr/wiki-analytics/container-balise">balise conteneur</Link> sur toutes les pages. Cependant, tous les tags ultérieurs peuvent être ajoutés via le TMS lui-même.</p>
<p>Le code du gestionnaire de tags doit être ajouté et déployé une seule fois au début et tous les tags suivants sont implémentés via l'interface utilisateur graphique de l'outil de gestion des tags.</p>

<ImgScreenshot
  src="tag-management/matomo-trigger-types.png"
  alt="Liste des types de déclencheurs disponibles dans l'outil Matomo Tag Manager"
  className="article-img"
  caption="Types de déclencheurs dans Matomo. Les déclencheurs définissent quand un certain code doit être exécuté et il y a une longue liste de choix - aucune compétence en codage requise."
/>

<p>La charge de travail est réduite et la procédure <b>grandement simplifiée</b>.</p>
<p>Grâce à l'implémentation simplifiée, les <Link to="/fr/consultant-google-tag-manager">consultants en gestion des tags</Link> pouvaient alors configurer la collecte de données des tags de suivi. Ce nouveau rôle dans l'organisation se concentre sur la programmation JavaScript uniquement pour les services d'analyse web et de marketing digital.</p>
<p>Chaque règle et chaque point de données pouvait désormais être utilisé à travers plusieurs tags et même à travers plusieurs domaines.</p>
<p>Une plateforme de gestion des tags a de nombreux avantages. Tellement en fait, que vous entendez rarement des plaintes.</p>

<H as="h2">Avantages</H>
<p>
  Un des grands avantages des systèmes de gestion des tags est qu'un spécialiste du marketing technique peut implémenter des tags marketing sans dépendre d'un développeur.
</p>

<p>L'interface utilisateur graphique permet à un spécialiste du marketing digital d'effectuer des implémentations de base qui seraient autrement trop difficiles en code brut.</p>

<p>Comme <b>exemple</b>, voici la liste des outils de marketing et des tags d'analyse que vous pouvez installer avec <b>Matomo Tag Manager</b> :</p>
<ImgScreenshot
  src="tag-management/matomo-tag-templates.png"
  alt="Liste des types de tags dans Matomo"
  className="article-img"
  caption="Liste des types de tags dans Matomo - Les tags sont fournis avec un modèle prédéfini, de sorte que seuls les champs de formulaire doivent être remplis pour l'implémentation."
/>
<p>Ci-dessous, vous voyez une liste des <b>options de tags</b> disponibles dans GTM (<a href="https://support.google.com/tagmanager/answer/6106924?hl=en" target="_blank" noopener>liste complète des tags pris en charge</a>). En comparaison, GTM a le plus de modèles de tags disponibles ainsi que des <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>modèles personnalisés</a>.</p>
<ImgScreenshot
  src="tag-management/gtm-tag-types.png"
  alt="Liste des tags dans GTM"
  className="article-img"
  caption="Liste des tags dans GTM - J'ai dû raccourcir la liste des tags pour des raisons d'espace. Il y a encore plus de modèles de tags si vous regardez sous modèles personnalisés."
/>

<p className="baseline">Étant donné que le déploiement des tags se fait en quelques secondes et qu'une équipe marketing n'a plus besoin d'attendre le déploiement du code sur le serveur, l'utilisation d'un TMS <b>fait gagner du temps, réduit les coûts et diminue la gestion des projets</b>.<br />Cela est confirmé dans un rapport de 2015 par Econsultancy, où <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% des marketeurs ont déclaré que l'utilisation d'un TMS aide à réduire les coûts</a>.</p>
<p>Avec cette flexibilité, des workflows agiles sont possibles, permettant des optimisations progressives des campagnes, des tests et des expérimentations.</p>
<p>Les campagnes de marketing digital peuvent être menées dans plus de variantes tout en ciblant des groupes plus spécifiques, ce qui augmente les conversions et fournit une bonne base pour apprendre comment les groupes réagissent à des messages personnalisés.</p>
<p>Les entreprises qui disposent déjà de silos de données, comme des bases de données, des CRM et des API, peuvent utiliser une <Link to="/fr/couche-de-données">couche de données</Link> pour rendre les données disponibles pour les règles de suivi des campagnes.</p>
<p>Un autre avantage sous-estimé est la disponibilité des paramètres pour les espaces de travail, les environnements et les droits des utilisateurs qui forment une solution de gestion permettant de fournir un accès à des agences ou des consultants externes. L'accès peut être accordé en quelques secondes pour permettre à quelqu'un de travailler sur un tag individuel ou sur l'ensemble du plan de balisage.</p>

<p>En résumé, les <b>avantages de la gestion des tags</b> sont les suivants :</p>
<ul>
  <li>Économies de coûts, grâce à des coûts d'implémentation plus faibles</li>
  <li>Implémentations rapides</li>
  <li>Flexibilité accrue puisque les modifications et les corrections peuvent être implémentées en quelques secondes</li>
  <li>Intégration robuste et stable des sources de données externes</li>
  <li>Possibilité d'attribuer facilement différents droits d'accès aux utilisateurs à des partenaires externes</li>
</ul>

<H as="h3">Inconvénients</H>
<p>Il y a une inquiétude fondée que la <b>vitesse de chargement du site web ralentisse</b> lors de l'utilisation d'un TMS.</p>
<p>Cependant, cela n'est le cas que dans une très faible mesure, en raison du chargement asynchrone des tags.</p>
<p className="baseline">Les sites web avec un TMS bien configuré <b>chargent généralement plus rapidement</b> que si les tags étaient implémentés directement dans le code source. Un rapport de 2015 par Econsultancy indique que <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% des utilisateurs de TMS constatent une amélioration des performances du site web</a>.</p>
<p>Comme l'optimisation de la vitesse de chargement est souvent brutalement négligée et en raison de la perte minimale de vitesse causée par un TMS, il existe généralement des méthodes beaucoup plus efficaces pour améliorer le temps de chargement que de renoncer complètement aux outils de gestion des tags.</p>

<p>Et maintenant, qui pensez-vous aurait suffisamment d'intérêt à faciliter la publicité digitale et le suivi des conversions pour développer une solution gratuite ? Ce doit être une entreprise qui bénéficie de la publicité digitale des autres 😉.</p>

<H as="h2">Comparaison des plateformes</H>

<p>Voici une comparaison des principales plateformes sur le marché des systèmes de gestion des tags :</p>

<table>
  <thead>
    <tr>
      <th>&nbsp;</th>
      <th>Prix</th>
      <th>Propriété<br />des données</th>
      <th>Disponibilité<br />d'experts</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>GTM (gratuit)</b></td>
      <td>gratuit</td>
      <td>non</td>
      <td>élevée</td>
    </tr>
    <tr>
      <td><b>GTM 360</b></td>
      <td>élevé</td>
      <td>oui</td>
      <td>élevée</td>
    </tr>
    <tr>
      <td><b>Adobe Launch</b></td>
      <td>élevé</td>
      <td>oui</td>
      <td>moyenne</td>
    </tr>
    <tr>
      <td><b>Tealium iQ</b></td>
      <td>moyen</td>
      <td>oui</td>
      <td>faible</td>
    </tr>
    <tr>
      <td><b>Matomo</b></td>
      <td>bas</td>
      <td>oui</td>
      <td>faible</td>
    </tr>
    <tr>
      <td><b>Piwik</b></td>
      <td>moyen</td>
      <td>oui</td>
      <td>faible</td>
    </tr>
  </tbody>
</table>
<p>Pour les entreprises, le choix se porte principalement sur des plateformes premium, comme <b>Google Tag Manager 360, Adobe Launch</b> (successeur d'Adobe DTM) ou <b>Tealium iQ</b>, car elles répondent aux exigences de confidentialité des données des clients.</p>
<p>
  Le prix d'un TMS premium de Google 360, Adobe ou Tealium ne peut pas être défini exactement, car ils sont vendus dans des packages configurables pour entreprises avec d'autres solutions d'analyse.
</p>

<p>
  <u>Les prix sont donc seulement approximatifs et doivent être considérés comme une orientation générale</u>. Les prix individuels sont connus pour varier.
</p>

<p>Si vous souhaitez voir à quoi ressemble <b>Tealium Tag Manager</b>, regardez leur vidéo de démonstration :</p>
<YouTube id="HF1ZiPQYP6M" />
<br />
<p>De même, voici une vidéo où vous pouvez voir l'interface de <b>Adobe Launch</b> et comment une règle de chargement de page y est configurée :</p>
<YouTube id="JR2mRfKU18Y" />
<br /><br />
<p>L'outil de gestion des tags le plus populaire est cependant la <a href="https://tagmanager.google.com/" target="_blank">version gratuite de Google Tag Manager</a>.</p>
<H as="h2">Cas d'utilisation courants</H>

<p>La collecte de données pour l'analyse web, le marketing digital ou la recherche en data science sont les utilisations les plus courantes des systèmes de gestion des tags.</p>

<H as="h3">Web Analytics</H>
<p>Les systèmes d'analyse web tels que Google Analytics sont rapides à configurer et plus faciles à mettre à l'échelle sur plusieurs domaines en utilisant un TMS. Toute addition ou modification ultérieure est mise en œuvre rapidement et nécessite moins de ressources, c'est pourquoi la gestion des tags est devenue une norme pour l'analyse digitale ces dernières années.</p>
<p><b>L'optimisation des taux de conversion (CRO)</b> est également largement basée sur les métriques d'un tag d'analyse et est donc fortement liée.</p>

<H as="h3">Marketing Digital</H>
<p>Les publicités sur des plateformes comme Facebook, Twitter, Instagram et Google Ads sont des canaux populaires d'acquisition de trafic. La publicité digitale par le reciblage avec des bannières ou Adwords est donc le cas d'utilisation le plus courant.</p>
<p>Cette technologie marketing est basée sur des tags qui nécessitent des points de données d'une ou plusieurs propriétés digitales pour créer des segments d'utilisateurs. C'est pourquoi la gestion des tags de manière contrôlable et évolutive est une condition préalable.</p>
<p><b>Le marketing d'affiliation</b> est basé sur l'attribution des conversions aux référents de trafic et dépend également fortement de la logique des cookies mise en œuvre par les gestionnaires de tags.</p>

<H as="h3">Data Science</H>
<p>Avec l'essor de l'IA, le besoin de données a augmenté car les modèles de données doivent être entraînés sur de grandes quantités de données.</p>
<p>Toutes les données qui ne peuvent pas être extraites de pages statiques sont généralement collectées avec une solution de gestion des tags. À partir de là, elles sont envoyées vers un stockage de données accessible pour la modélisation des données.</p>

<H as="h2">Consulting en gestion des tags</H>
<p>Les services de consulting en gestion des tags sont apparus en même temps que la croissance du marché : Des fournisseurs supplémentaires sont apparus avec de nouvelles solutions et des points de prix à prendre en considération.</p>
<p>De plus, les <b>lois de protection des données des utilisateurs dans l'UE</b> (RGPD) ont modifié les exigences de configuration et ont donné un coup de pouce aux fournisseurs axés sur la confidentialité.</p>
<p>Le consulting en gestion des tags se concentre sur la sélection de la plateforme, l'implémentation, la validation et la maintenance du système.</p>
<p>Les consultants en gestion des tags sont généralement embauchés par des agences spécialisées dans la publicité digitale, l'analyse web ou sont des <Link to="/fr/consultant-google-tag-manager">consultants indépendants</Link>.</p>

<H as="h2">Y a-t-il des cas où un TMS n'est pas la bonne solution ?</H>
<p><b>Un système de gestion des tags est le bon choix pour la plupart des sites web et des applications</b>. Seuls les sites opérant dans des secteurs avec des exigences spécifiques en matière de confidentialité des données devraient examiner attentivement la façon dont l'infrastructure est conçue pour se conformer à la législation sur la protection des données.</p>
<p>Cependant, ce n'est généralement pas un argument contre l'utilisation d'un TMS, mais plutôt un argument pour choisir le bon.</p>
<p>Une autre considération peut être les performances du site.</p>
<p>Si le TMS n'est pas configuré correctement, il peut ralentir un site web. Les niches où la publicité joue un rôle important peuvent avoir du mal avec des temps de chargement lents. Gagner quelques centaines de millisecondes pourrait ajouter de la valeur, mais il est discutable si les avantages d'un temps de chargement plus rapide justifient des coûts plus élevés pour gérer les pixels de suivi.</p>
<p>En fonction des capacités des ressources internes, il est possible de gérer un site web et le marketing digital avec succès sans TMS, mais ce sont généralement des cas exceptionnels.</p>
<p>Tout site web qui n'a pas un nombre extrême de pages ou des exigences spécifiques pour les tags tiers devrait être en mesure de contrôler ses tags HTML et JavaScript avec un TMS.</p>
<Helmet>
  <script type="application/ld+json">{FAQs}</script>
</Helmet>
</MainContent>
</React.Fragment>

</Layout>
);

export default TagManagement;




  